import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebookF } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <section id="contact-us">
      <footer className="footer py-4">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-3 d-flex flex-column align-items-center">
              <a href="tel:+91 8734924492">
                <FontAwesomeIcon icon={faPhone} size="2x" />
                <p className="mt-2">+91 8734924492</p>
              </a>
            </div>

            <div className="col-md-3 d-flex flex-column align-items-center">
              <a href="mailto:shalviartazy@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
                <p className="mt-2">shalviartazy@gmail.com</p>
              </a>
            </div>
            <div className="col-md-3 d-flex flex-column align-items-center">
              <a href="https://instagram.com/artazy_shalvi" className="">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
                <p className="mt-2">@artazy_shalvi</p>
              </a>
            </div>
            <div className="col-md-3 d-flex flex-column align-items-center">
              <a href="https://facebook.com" className="">
                <FontAwesomeIcon icon={faFacebookF} size="2x" />
                <p className="mt-2">@artazy_shalvi</p>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
