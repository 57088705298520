import React from "react";
import { Carousel, Button } from "react-bootstrap";
import lipinArt from "../assets/images/lipin art.webp";
import resinArt from "../assets/images/resin art.webp";
import hamperDesign from "../assets/images/Hamper Design.webp";
import canvasPainting from "../assets/images/Canvas Painting1.webp";
const Hero = () => {
  return (
    <section id="home">
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
      >
        <div className="carousel-item">
          <img src={resinArt} alt="First Slide" />
          <div className="overlay"></div>
          <div className="caption">
            <h3>Resin Art</h3>
          </div>
        </div>{" "}
        <div className="carousel-item">
          <img src={canvasPainting} alt="First Slide" />
          <div className="overlay"></div>
          <div className="caption">
            <h3>Canvas Painting</h3>
          </div>
        </div>
        <div className="carousel-item">
          <img src={lipinArt} alt="Third Slide" />
          <div className="overlay"></div>
          <div className="caption">
            <h3>Lippan Art</h3>
          </div>
        </div>
        <div className="carousel-item">
          <img src={hamperDesign} alt="Second Slide" />
          <div className="overlay"></div>
          <div className="caption">
            <h3>Hamper Desgin</h3>
          </div>
        </div>
      </Carousel>
    </section>
  );
};

export default Hero;
