import React from "react";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import Products from "./Products";
import OurStory from "./OurStory";
const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <AboutUs />
      <Products />
      <OurStory />
    </React.Fragment>
  );
};
export default Home;
