import React from "react";

const OurStory = () => {
  return (
    <section id="our-story">
      <div className="our-story">
        <div className="container">
          <h2>Our Story</h2>
          <p>
            Artazy_Shalvi began as a dream of expressing creativity through
            diverse artistic mediums. Inspired by the intricate designs of
            traditional Lippan art and the limitless possibilities of resin, we
            set out to craft pieces that not only decorate spaces but also tell
            a story. Each piece we create is infused with passion, creativity,
            and meticulous attention to detail, reflecting our commitment to
            excellence.
          </p>

          <h2>Our Art</h2>
          <div className="art-section">
            <div className="art-item">
              <h3>Resin Art</h3>
              <p>
                Our resin creations are a harmonious blend of vibrant colors and
                intricate patterns. From coasters to wall art, each piece is a
                testament to the fluid beauty and versatility of resin.
              </p>
            </div>
            <div className="art-item">
              <h3>Lippan Art</h3>
              <p>
                Drawing from the rich heritage of Lippan (Mud) art, our pieces
                feature stunning designs that add a touch of traditional
                elegance to any space. Each artwork is handcrafted, showcasing
                the timeless beauty of this ancient art form.
              </p>
            </div>
            <div className="art-item">
              <h3>Customized Hampers</h3>
              <p>
                Whether it's a special occasion or a heartfelt gesture, our
                customized hampers are designed to make every moment memorable.
                Each hamper is carefully curated to reflect the recipient's
                personality and preferences, making it a truly unique gift.
              </p>
            </div>
          </div>

          <h2>Our Mission</h2>
          <p>
            Our mission at Artazy_Shalvi is to bring joy and inspiration through
            our art. We aim to create pieces that resonate with our clients,
            adding beauty and meaning to their lives. Every artwork and hamper
            is crafted with love and care, ensuring that it not only meets but
            exceeds expectations.
          </p>

          <h2>Why Choose Us?</h2>
          <ul>
            <li>
              <strong>Passion and Creativity:</strong> Our passion for art
              drives us to create unique, high-quality pieces that stand out.
            </li>
            <li>
              <strong>Customization:</strong> We believe in the power of
              personal touch, offering customized options to make each piece
              special.
            </li>
            <li>
              <strong>Quality Craftsmanship:</strong> Our commitment to
              excellence ensures that every product is crafted with precision
              and care.
            </li>
          </ul>

          <p>
            Thank you for visiting Artazy_Shalvi. We look forward to sharing our
            art with you and being a part of your special moments. Whether
            you're looking for a beautiful piece of art or a personalized gift,
            we're here to bring your vision to life. Feel free to explore our
            collection and let us know how we can create something amazing for
            you!
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
