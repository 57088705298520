import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from "react-modal";
import emailjs from "emailjs-com";
import lipinArt from "../assets/images/lipin art.webp";
import hamperDesgin from "../assets/images/Hamper Design.webp";
import resinArt from "../assets/images/resin art.webp";
import cavasPainting from "../assets/images/Canvas Painting.webp";
import cavasPainting1 from "../assets/images/Canvas Painting1.webp";
import cavasPainting2 from "../assets/images/Canvas Painting2.webp";

import resinArt1 from "../assets/images/resin art1.webp";
import resinArt2 from "../assets/images/resin art2.webp";
import resinArt3 from "../assets/images/resin art3.webp";
import resinArt4 from "../assets/images/resin art4.webp";
import resinArt5 from "../assets/images/resin art5.webp";
import resinArt6 from "../assets/images/resin art6.webp";
import resinArt7 from "../assets/images/resin art7.webp";
import resinArt8 from "../assets/images/resin art8.webp";
import resinArt9 from "../assets/images/resin art9.webp";
import resinArt10 from "../assets/images/resin art10.webp";
import resinArt11 from "../assets/images/resin art11.webp";
import resinArt12 from "../assets/images/resin art12.webp";
import resinArt13 from "../assets/images/resin art13.webp";
import resinArt14 from "../assets/images/resin art14.webp";
import resinArt15 from "../assets/images/resin art15.webp";
import resinArt16 from "../assets/images/resin art16.webp";
import resinArt17 from "../assets/images/resin art17.webp";
import resinArt18 from "../assets/images/resin art18.webp";
import resinArt19 from "../assets/images/resin art19.webp";
import resinArt20 from "../assets/images/resin art20.webp";
import resinArt21 from "../assets/images/resin art21.webp";
Modal.setAppElement("#root"); // Bind modal to the app element

const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1, // Show 1 item on mobile
          slidesToScroll: 1,
        },
      },
    ],
  };

  const products = [
    {
      image: lipinArt,
      name: "Lippan Art",
      description: "Description 1",
      price: "10.00",
      category: "Lippan Art",
    },
    {
      image: hamperDesgin,
      name: "Hamper Design",
      description: "Description 2",
      price: "20.00",
      category: "Hamper Desgin",
    },
    {
      image: cavasPainting,
      name: "Canvas Painting",
      description: "Description 2",
      price: "20.00",
      category: "Canvas Painting",
    },
    {
      image: resinArt3,
      name: "Resin Photo Frame",
      description: "Description 2",
      price: "20.00",
      category: "Resin Art",
    },
    {
      image: cavasPainting1,
      name: "Canvas Painting ",
      description: "Description 2",
      price: "20.00",
      category: "Canvas Painting",
    },
    {
      image: resinArt,
      name: "Resin Navkar Mantra Frame",
      description: "Description 3",
      price: "30.00",
      category: "Resin Art",
    },
    {
      image: resinArt4,
      name: "Resin Photo Frame",
      description: "Description 4",
      price: "40.00",
      category: "Resin Art",
    },
    {
      image: cavasPainting2,
      name: "Canvas Painting",
      description: "Description 5",
      price: "50.00",
      category: "Canvas Painting",
    },
    {
      image: resinArt5,
      name: "Resin Photo Frame",
      description: "Description 5",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt1,
      name: "Resin Photo Frame",
      description: "Description 6",
      price: "60.00",
      category: "Resin Art",
    },
    {
      image: resinArt6,
      name: "Resin Photo Frame",
      description: "Description 7",
      price: "70.00",
      category: "Resin Art",
    },
    {
      image: resinArt2,
      name: "Resin Navkar Mantra Frame",
      description: "Description 8",
      price: "80.00",
      category: "Resin Art",
    },
    {
      image: resinArt17,
      name: "Resin Photo Frame",
      description: "Description 17",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt18,
      name: "Resin Photo Frame",
      description: "Description 18",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt19,
      name: "Resin Photo Frame",
      description: "Description 19",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt20,
      name: "Resin Photo Frame",
      description: "Description 20",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt21,
      name: "Resin Photo Frame",
      description: "Description 21",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt7,
      name: "Resin Photo Frame",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt8,
      name: "Resin Photo Frame",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt9,
      name: "Resin Photo Frame",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt10,
      name: "Resin Keychain",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt11,
      name: "Resin Keychain",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt12,
      name: "Resin Keychain",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt13,
      name: "Resin Keychain",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt14,
      name: "Resin Keychain",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt15,
      name: "Resin Keychain",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
    {
      image: resinArt16,
      name: "Resin Keychain",
      description: "Description 9",
      price: "50.00",
      category: "Resin Art",
    },
  ];

  const categories = [
    "All",
    "Hamper Desgin",
    "Resin Art",
    "Lippan Art",
    "Canvas Painting",
  ];

  const filteredProducts =
    selectedCategory === "All"
      ? products
      : products.filter((product) => product.category === selectedCategory);

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .send(
        "YOUR_SERVICE_ID", // replace with your EmailJS service ID
        "YOUR_TEMPLATE_ID", // replace with your EmailJS template ID
        {
          ...formData,
          product_name: selectedProduct.name,
          product_description: selectedProduct.description,
          product_price: selectedProduct.price,
        },
        "YOUR_USER_ID" // replace with your EmailJS user ID
      )
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        closeModal();
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  return (
    <section id="products">
      <div className="products">
        <div className="container">
          <div className="d-flex justify-content-between">
            <h2 className="text-center">Our Products</h2>
            <div className="d-flex flex-row-reverse p-2 align-items-center">
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="category-dropdown ms-2 p-2"
              >
                {categories.map((category, index) => (
                  <option
                    key={index}
                    value={category}
                    className="category-option"
                  >
                    {category}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <Slider {...settings}>
            {filteredProducts.map((product, index) => (
              <div
                key={index}
                className="product"
                onClick={() => openModal(product)}
              >
                <img src={product.image} alt={product.name} />
                <h4>{product.name}</h4>
                {/* <p>{product.description}</p> */}
                {/* <p>${product.price}</p> */}
              </div>
            ))}
          </Slider>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Product Details"
            className="custom-modal"
            overlayClassName="custom-overlay"
          >
            {selectedProduct && (
              <div>
                <button onClick={closeModal} className="close-button">
                  x
                </button>
                <h2 className="text-center text-dark">
                  {selectedProduct.name}
                </h2>

                <form onSubmit={handleSubmit}>
                  <div>
                    <label className="text-dark">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="text-dark">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="text-dark">Phone No.</label>
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <label className="text-dark category">Select Category</label>
                  <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="category-dropdown p-2"
                  >
                    {categories.map((category, index) => (
                      <option key={index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>

                  <button type="submit" className="mt-4">
                    Send
                  </button>
                </form>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </section>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "black" }}
      onClick={onClick}
    />
  );
};

export default Products;
