import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";

const HeaderComponent = () => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sectionOffsets = [];
      const sections = document.querySelectorAll("section");

      sections.forEach((section) => {
        const id = section.id;
        const top = section.offsetTop;
        const bottom = top + section.offsetHeight;
        sectionOffsets.push({ id, top, bottom });
      });

      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const threshold = 450;
      const bottomThreshold = 50;
      let activeId = null;

      sectionOffsets.forEach((offset) => {
        if (
          (scrollPosition >= offset.top - threshold &&
            scrollPosition < offset.bottom) ||
          (offset.id === sectionOffsets[sectionOffsets.length - 1].id &&
            scrollPosition + windowHeight >= offset.bottom - bottomThreshold)
        ) {
          activeId = offset.id;
        }
      });

      setActiveSection(activeId);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Navbar className="header-component" sticky="top" expand="lg">
        <div className="container">
          <Navbar.Brand href="#home">Artazy_Shalvi</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link
                className={activeSection === "home" ? "active" : ""}
                href="#home"
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={activeSection === "products" ? "active" : ""}
                href="#products"
              >
                Products
              </Nav.Link>
              <Nav.Link
                className={activeSection === "our-story" ? "active" : ""}
                href="#our-story"
              >
                About Us
              </Nav.Link>
              <Nav.Link
                className={activeSection === "contact-us" ? "active" : ""}
                href="#contact-us"
              >
                Contact Us
              </Nav.Link>
            </Nav>
            <Nav>
              <Button variant="outline" href="#products" className="buy-now">
                Buy Now
              </Button>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default HeaderComponent;
