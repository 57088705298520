import React from "react";

const AboutUs = () => {
  return (
    <section id="about-us">
      <div className="about-us">
        <div className="container justify-content-center">
          <h2>Welcome to Artazy_Shalvi!</h2>
          <p>
            At Artazy_Shalvi, we believe that art is more than just a visual
            experience—it's a journey that touches the heart and soul. Founded
            by a passionate artist, our company brings together the beauty of
            resin art, the tradition of Lippan (Mud) art, and the personal touch
            of customized hampers to create unique and memorable pieces.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
